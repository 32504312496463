.codexHeaderWrapper{
    display:flex;
}
.codexLeftBlock{
    margin-right: 100px;
}


.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 40px !important;
    line-height: 1;
    opacity: .75;
    color: green !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.codexSabak{
    font-size: 12px;
    color: #333333;
    font-style: italic;
    margin-top:30px;
}
.codexRightBlock h1{
    font-size:50px;
}
.codexRightBlock h2{
    font-size:25px;
}
.codexRightBlock h3{
    font-size:16px;
    font-weight: normal;
}
.codexSliderWrapper{
    background: rgb(240,245,213);
    background: linear-gradient(262deg, rgba(240,245,213,1) 0%, rgba(255,255,255,1) 100%);
    min-height:500px;
    margin-top:100px;
    width:100%;
    padding-top:100px;
}
.sliderWrapper{
    margin-top:200px;
}
.sliderBeforeWrapper{
    display:flex;
}
.sliderBeforeWrapper img{
    margin-right: 200px;
}
.sliderBeforeTextWrap{
    margin-top:50px;
}
.sliderBeforeTextTitle{
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 50px;
}
.sliderBeforeText{
    font-size: 20px;
    font-weight: bold;
    color:#999;
}
.slideWrapper{
    display: flex;
    padding-bottom: 100px;
    min-height: 300px;
    align-items: flex-start;
}
.slideWrapper img{
    margin-right:100px;
}
.slideLeftBlock{
    padding-top:30px;
}
.slideLeftBlockText{
    margin-top:30px;
    font-size: 25px;
    font-weight: bold;
}
