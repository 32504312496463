.App{
    display:flex;
}
.MainContentLeftBlock{
    max-width:50%;
    margin-right: 300px;
}
.logoWwf{
    position: fixed;
    bottom:100px;
    left:100px;
}
.ScenarioAllAnons{
    padding:10px 20px;
    color: tomato;
    margin-bottom: 20px;
    font-weight: 600;
}
.text{
    font-weight: bold;
    font-size: 30px;
}
.optionMy{
    padding:10px 40px;
    border-radius: 10px;
    box-shadow: 0 0 0 1px rgba(34,36,38,.22) inset, 0 2px 4px 0 rgba(34,36,38,.12), 0 2px 10px 0 rgba(34,36,38,.15)
}
.MainContentRightBlock button:hover{
    background: transparent !important;
    border:1px solid #000 !important;
    color:#000 !important;
}
.MainContentRightBlock button{
    clear: both !important;
    display: block !important;
    color: #fff !important;
    background: #000 !important;
    font-size: 25px !important;
    margin-top:120px !important;
    border:1px solid transparent !important;
    transition: ease 1s !important;
    box-shadow: 0 0 0 1px rgba(34,36,38,.22) inset, 0 2px 4px 0 rgba(34,36,38,.12), 0 2px 10px 0 rgba(34,36,38,.15) !important;
    padding:10px 20px;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
}
.MainContentRightBlock img{
    margin-top: 90px;
}
.MainContentBlockWrap h1{
    font-size: 50px;
}
.MainFormAlert{
    color:red;
    margin-top:20px;
    font-size:20px;
}
.field label{
    margin-bottom:20px !important;
    font-size: 20px !important;
    color:#666 !important;
    font-weight: normal !important;       
}
.field input:focus{
    border: 1px solid #ffad00;
 }
.field input{
    width: 100%;
    font-size: 16px;
    border:1px solid #dedede;
    border-radius: 5px;
    padding:10px 20px;
    outline:none; 
    margin-top:10px;
    transition:ease 1s;
    font-weight: normal;
}
.MainContentAnons{
    font-size: 25px;
    margin-bottom: 30px;
}
.MainContentBlockWrap{
    display: flex;
    align-items: flex-start;
}
*::selection {
    background: green;
    color:#fff;
}
.contentLeftPadding{
    margin-left: 260px;
}
.btnNextPad{
    margin-top: 100px;
}
.btnNext{
    background-color: transparent;
    border:0;
    outline: none;
}
.btnNext a{
    padding: 10px 20px;
    border: 0;
    border-radius: 10px;
    background-color: green;
    color: #fff;
    font-size: 20px;
    margin-top: 20px;
    cursor: pointer;
    outline: none;
    transition: ease 1s;
    text-decoration: none;
    margin-bottom: 100px;
    display:block;
}
.btnNext a:hover{
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    opacity: 0.5;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 12px !important;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: green !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-prev {
    display:none !important;
}
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    right:-50px !important;
    display: block;
    width: 40px !important;
    height: 40px !important;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.imageMain{
    width:100%;
    height:auto;
}
.contentWrapper{
    width:100%;
    padding-left: 260px;
    margin-left:260px;
}
.container{
    max-width:1200px;
    margin:0 auto;
    padding:0 15px;
}
.navbar{
    max-width:300px;
}
.navbarLink a{
    text-decoration: none;
    color: inherit;
    padding: 15px 20px;
    color: #707070;
    outline-offset: -.4rem;
    transition-duration: .3s;
    transition-property: color,background;
    font-size: 14px;
    display:flex;
    border-left:5px solid transparent;
}
.navbarLink a.active {
    color: #313537;
    background: #f3f3f3;
    border-left:5px solid #637969;
}
.navbarLink a:hover {
    color: #313537;
    background: #f3f3f3;
}
.navbarLink a:hover > .lesson-link__icon {
    opacity: 1;
}
.lesson-link__icon{
    margin-right:20px;
    opacity: 0.5;
    transition:ease 1s;
}
.navbarLink a.active > .lesson-link__icon{
    opacity: 1;
}
.navbarWrapper{
    position:fixed;
}