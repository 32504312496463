.Scenario1Wrapper{
    display:flex;
}
.Scenario1TwoRightTitle{
    font-size:20px;
    font-weight: bold;
    margin-bottom:30px;
}
.Scenario1FourTitle{
    font-size: 30px;
    font-weight: 700;
    padding-bottom:20px;
}
.Scenario1FourText img{
    position: absolute;
    top:0;
    left:-60px;
}
.Scenario1TwoRightCheck .bold{
    font-weight: 700;
}
.Scenario1FourText{
    padding-bottom:20px;
    font-size: 18px;
    position: relative;
}
.Scenario1FourAnons, .Scenario1FourPs{
    font-size: 18px;
    font-weight: 700;
    padding-bottom:20px;
}
.Scenario1TwoWraper img{
    margin-right:80px;
    width:100%;
    height:auto;
}
.Scenario1TwoRightCheckWrap > .disabled{
    color:#ccc;
}
.Scenario1Video3{
    margin-bottom:100px;
}
.Scenario1FourWrap{
    max-width:600px;
    padding-top:100px;
    margin-left:70px;
}
.Scenario1TwoWrap button{
    border-radius: 20px;
    border:0;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5rem;
    padding: 10px 20px;
    cursor: pointer;
    color: #fff;
    text-transform: uppercase;
    outline:none;
    background-color: green !important;
    margin-top:100px;
    margin-bottom:100px;
    transition: ease 1s;
}
.Scenario1TwoRightBlock{
    text-align:left;
}
.Scenario1TwoWraper{
    display: flex;
}
.Scenario2Wrapper{
    display:flex;
}
.Scenario2Img{
    margin-right:80px;
}
.Scenario2Content h1 {
    font-size: 55px;
}
.Scenario1TwoWrap{
    margin-top:100px;
    text-align:center;
}
.Scenario1Img{
    margin-right:100px;
}
.Scenario1Content h1 {
    font-size: 55px;
}
.Scenario1VideoWrap{
    margin-top:100px;
    min-height: 700px;
}
iframe{    
    width: 1200px;
    height:700px;
    margin-bottom:200px;
}
.Scenario1TwoRightCheck {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
.Scenario1TwoRightCheck  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 3px;
    left: 10px;
    height: 17px;
    width: 17px;
    background-color: #eee;
  }
  .Scenario1TwoRightCheck:hover input ~ .checkmark {
    background-color: #ccc;
  }
  .Scenario1TwoRightCheck input:checked ~ .checkmark {
    background-color: #637969;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .Scenario1TwoRightCheck input:checked ~ .checkmark:after {
    display: block;
  }
  .Scenario1TwoRightCheck .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 7px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }