.react-tabs__tab{
    list-style: none;
    padding:10px 10px 10px 30px;
    cursor: pointer;
    color:#bfbfbf;
    outline: none;
    transition: ease 0.5s;
    font-size:16px;
    background: url(../../img/homeTabsPlus.jpg) left center no-repeat;
}
.react-tabs__tab--selected{
    list-style: none;
    padding:10px 10px 10px 30px;
    cursor: pointer;
    color:#bfbfbf;
    outline: none;
    transition: ease 0.5s;
    font-size:16px;
    background: url(../../img/homeTabsMinus.jpg) left center no-repeat;
}
.HomeLastItem > button > a:hover{
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    opacity: 0.5;
}
.HomeVideo{
    min-height: 700px;
}
.HomeLastItem > button{
    cursor: pointer;
    background-color: transparent;
    border:0;
}
.HomeLastItem > button > a{
    padding: 10px 20px;
    border: 0;
    border-radius: 10px;
    background-color: green;
    color: #fff;
    font-size: 20px;
    margin-top: 20px;
    cursor: pointer;
    outline: none;
    transition: ease 1s;
    text-decoration: none;
}
.HomeLastItem{
    max-width:17%;
}
.HomeLastItemText{
    min-height: 200px;
}
.HomeLastItemTitle{
    margin:20px 0;
    font-weight: bold;
    font-size: 20px;
    min-height: 70px;
}
.HomeLastWrapper{
    margin-bottom:100px;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    margin-top:100px;
    justify-content: space-between;
}
.react-tabs__tab:hover, .react-tabs__tab--selected{
    color:#000;
}
.HomeTab1{
    min-height: 700px;
}
.HomeTabTitle{
    font-size: 30px;
    font-weight: bold;
    padding:30px 0;
}
.HomeTabsContent{
    width:60%;
    margin-right: 50px;
}
.HomeTabsLinks{
    width:40%;
    padding:0;
    margin:0;
}
.react-tabs{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
}
.HomeTabsWrapper{
    margin-top: 200px;
}
.HomeVideo{
    margin-top:200px;
    margin-bottom:200px;
}
.homeWindowTwoRight img{
    margin-top:120px;
}
.homeWindowTwoManName{
    margin-top:20px;
}
.homeWindowTwoManOne{
    margin-right:10px;
}
.homeWindowTwoRightText{
    margin-top:100px;
}
.homeWindowTwoLeft{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    margin-right: 100px;
}
.homeWindowTwo{
    margin-top:200px;
    margin-bottom:200px;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    justify-content: space-between;
}
.homeWindowOne{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
}
.homeImages{
    width:550px;
    margin-right:50px;
}
.homeImages img{
    max-width:250px;
    height:auto;
    margin:0 10px 10px 0;
}
.contentWrapper{
    padding-top:100px;
}
.homeContent{
    width:500px;
}
.lessonNumber{
    font-size: 12px;
    color:#333333;
    font-style:italic;
}
.homeAnons{
    margin-top:100px;
}
.homeContent h1{
    font-size:50px;
}