.test-wrap p.selected{
    background-color: #8d8d8d;
    color:#fff;
}
.rightTest{
    margin:30px 0;
    color:#fff;
    border-radius: 10px;
    padding:20px 20px 20px 100px;
    font-size: 20px;
    background: url(../../img/correct.png) top left no-repeat #4b9f00;
    background-position: 20px 25px;
}
.wrongTest{
    margin:30px 0;
    color:#fff;
    border-radius: 10px;
    padding:20px 20px 20px 100px;
    font-size: 20px;
    background: url(../../img/incorrect.png) top left no-repeat red;
    background-position: 20px 25px;
}
.displayAnswer{
    display:block;
}
.dontDisplayAnswer{
    display:none;
}
.test-wrap p{
    cursor: pointer;
    transition: ease 1s;
}
.test-wrap p:hover{
    background-color: #8d8d8d;
    color:#fff;
}
.test-wrap button{
    padding:10px 20px;
   border:0;
   border-radius: 10px;
   background-color: green; 
   color:#fff;
   font-size: 20px;
   margin-top: 20px;
   cursor: pointer;
   outline:none;
   transition: ease 1s;
}
.test-wrap button:disabled{
    display:none;
    cursor: auto;
}
.testBackgound{
    background: rgb(251,229,214);
    background: linear-gradient(262deg, rgba(251,229,214,1) 0%, rgba(255,255,255,1) 100%);
    height: 100% !important;
    min-height: 1000px;
}
.testWrapper{
    display: flex;
    align-items: flex-start;
}
.testWrapperText{
    margin-left:100px;
}
.testWrapperText button:hover{
    opacity: 0.5;
}
.testWrapperText button{
    padding: 10px 20px;
    border: 0;
    border-radius: 10px;
    background-color: green;
    color: #fff;
    font-size: 20px;
    margin-top: 20px;
    cursor: pointer;
    outline: none;
    transition: ease 1s;
}