.LastAnons{
    font-size: 12px;
    color: #333333;
    font-style: italic;
}
.LastTitle{
    font-size: 50px;
}
.LastText{
    padding:20px 0;
}
.LastTextStrong{
    padding-bottom:50px;
}