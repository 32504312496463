.codexHeaderWrapper{
    display:flex;
}
.codexLeftBlock{
    margin-right: 100px;
}
.codexSabak{
    font-size: 12px;
    color: #333333;
    font-style: italic;
    margin-top:30px;
}
.codexRightBlock h1{
    font-size:50px;
}
.codexRightBlock h2{
    font-size:25px;
}
.codexRightBlock h3{
    font-size:16px;
    font-weight: normal;
}
.contentBorderTop{
    padding-top: 100px;
}
.eshcuFirstLeftWrap{
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
}
.eshcuFirstText{
    max-width:800px;
    font-size: 20px;
    font-weight: 600;
    padding-top:100px;
}
.eshcuFirstText h4{
    font-size:30px;
}
.EshcuFourWrap{
    display: flex;
    margin-top:100px;
    margin-bottom: 50px;
    align-items: flex-start;
}
.EshcuFourWrap img{
    margin-right:100px;
}
.EshcuFourText{
    font-size: 20px;
    font-weight: 700;
}
.EshcuFourText h4{
    font-size: 30px;
    padding-top:0;
    margin-top:0;
}
.contentBorder{
    border-bottom: 1px solid #e9e9e9;
    padding-bottom: 100px;
}
.EshcuFiveText{
    background-color:#f3f3f3;
    max-width: 490px;
    font-size:20px;
    font-weight: 700;
    padding:10px 40px 10px 40px;
    border-radius: 20px;
    margin-bottom: 20px;
    margin-right:80px;
    position: relative;
}
.EshcuFiveImagesText{
    display: flex;
    align-items: flex-start;
}
.EshcuFiveText img{
    position: absolute;
    right:-62px;
    top:50px;
}
.EshcuFivePs{
    max-width: 760px;
    text-align:right;
    margin-bottom:100px;
}
.EshcuSixWrap{
    margin-bottom:100px;
    max-width:800px;
}
.EshcuSixTitle{
    font-size:50px;
    font-weight: 700;
}
.EshcuSixText{
    font-size:25px;
    padding:30px 0;
}
.EshcuSixAnons{
    font-size:20px;
}
.EshcuSliderWrap{
    margin-bottom:200px;
}
.EshcuSlideWrapper{
    min-height: 400px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.EshcuSlideNum{
    color:#c00000;
    font-size:150px;
}
.EshcuSlideTextWrap{
    max-width:600px;
    margin:0 100px;
}
.EshcuSlideTextTitle{
    font-size:40px;
}
.EshcuSlideText{
    padding-top:40px;
    font-size: 20px;
}
.EshcuSlideText span{
    font-size: 30px;
}
.EshcuSevenWrap{
    margin-bottom: 200px;
}
.EshcuSevenTextWrap{
    display: flex;
    align-items: flex-start;
}
.EshcuSevenTextTitle{
    font-size:30px;
    padding-left:60px;
    margin-bottom:20px;
}
.EshcuSevenText ul{
    padding:0;
    margin:0 0 0 50px;
}
.EshcuSevenText ul li{
    font-size:20px;
    padding:9px 0;
    margin:-1px 0 0 0;
}